import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { get } from "lodash";
import favicon from "./images/favicon.png";
import logolarge from "./images/logolarge.png";
import dashboard from "./images/1_dashboard.svg";
import videomanager from "./images/1_video-manager.svg";
import usermanager from "./images/1_user-manager.svg";
import ordermanager from "./images/1_order-manager.svg";
import accountmanager from "./images/1_accounts-manager.svg";
import marketingmanager from "./images/1_marketing-mangar.svg";
import productmanager from "./images/1_product-manager.svg";
import settingmanager from "./images/1_product-manager.svg";
import supportmanager from "./images/1_support-manager.svg";
import sellerrewards from "./images/1_seller-reward.svg";
import walletmanager from "./images/1_wallet-offer.svg";
class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      if (menuState === "sellerOrderMenuOpen") {
        this.setState({ [menuState]: true, orderManagerOpen: true });
      } else if (
        menuState === "sellerMenuOpen" ||
        menuState === "buyerMenuOpen" ||
        menuState === "sellersMenuOpen"
      ) {
        this.setState({ [menuState]: true, userManagerMenuOpen: true });
      } else if (
        menuState === "buyerMenuOpen" ||
        menuState === "buyersMenuOpen"
      ) {
        this.setState({ [menuState]: true, userManagerMenuOpen: true });
      } else if (
        menuState === "storeMenuOpen" ||
        menuState === "buyerMenuOpen"
      ) {
        this.setState({ [menuState]: true, storeManagerMenuOpen: true });
      } else if (menuState === "SellerNpManagerOpen") {
        this.setState({ [menuState]: true, SellerNpManagerOpen: true });
      } else if (
        [
          "miscellaneousMenuOpen",
          "accountManagerMenuOpen",
          "gstManagerMenuOpen",
          "productManagerMenuOpen",
          "sellerRewardMenuOpen",
          "buddyRewardMenuOpen",
          "supportManagerMenuOpen",
        ].includes(menuState)
      ) {
        this.setState({ [menuState]: true, viewMoreMenuOpen: true });
      } else if (menuState === "rsfDashBoardOpen") {
        this.setState({ [menuState]: true, rsfDashBoardOpen: true });
      } else {
        this.setState({ [menuState]: true });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/customer-order-manager", state: "orderManagerOpen" },
      { path: "/OrderSearch", state: "orderManagerOpen" },
      { path: "/reward-orders-coupon", state: "orderManagerOpen" },
      { path: "/reward-orders-product", state: "orderManagerOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/users", state: "userManagerMenuOpen" },
      { path: "/agents", state: "userManagerMenuOpen" },
      { path: "/delete-accounts", state: "userManagerMenuOpen" },
      { path: "/search-users", state: "userManagerMenuOpen" },
      { path: "/vendor-requests", state: "requestManagerMenuOpen" },
      { path: "/influencer-requests", state: "requestManagerMenuOpen" },
      { path: "/products", state: "productManagerMenuOpen" },
      { path: "/category-manager", state: "productManagerMenuOpen" },
      { path: "/reward-manager", state: "productManagerMenuOpen" },
      { path: "/withdrawal-manager", state: "accountManagerMenuOpen" },
      { path: "/reward-seller", state: "sellerRewardMenuOpen" },
      { path: "/buddy-reward", state: "buddyRewardMenuOpen" },
      { path: "/buddy-Withdrawal", state: "buddyRewardMenuOpen" },
      { path: "/Withdrawal-seller", state: "sellerRewardMenuOpen" },
      { path: "/call-enquiry", state: "supportManagerMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/vendor-wallet", state: "walletManagerMenuOpen" },
      { path: "/offer-wallet", state: "walletManagerMenuOpen" },
      { path: "/video-call-orders", state: "accountManagerMenuOpen" },
      { path: "/NAC-kikopayment", state: "accountManagerMenuOpen" },
      { path: "/delivery-manager", state: "deliveryManagerMenuOpen" },
      { path: "/delivery-data", state: "deliveryManagerMenuOpen" },
      { path: "/b2b-logistic-orders-admin", state: "itcB2bLogisticsMenuOpen" },
      { path: "/itc-b2b-orders", state: "itcB2bLogisticsMenuOpen" },
      { path: "/itc-b2b-logs-manager", state: "itcB2bLogisticsMenuOpen" },
      { path: "/itc-b2b-users", state: "itcB2bLogisticsMenuOpen" },
      { path: "/selers-orders", state: "gstManagerMenuOpen" },
      { path: "/buyer-orders", state: "gstManagerMenuOpen" },
      { path: "/ondc-gst-manager", state: "gstManagerMenuOpen" },
      { path: "/taskbucks-buyers", state: "miscellaneousMenuOpen" },
      {
        path: "/microwebsite-order-manager",
        state: "microwebsiteOrderManagerMenuOpen",
      },
      {
        path: "/microwebsite-settlement",
        state: "microwebsiteOrderManagerMenuOpen",
      },
      {
        path: "/microwebsite-admin-order",
        state: "microwebsiteOrderManagerMenuOpen",
      },
      {
        path: "/logistic-website-orders-admin",
        state: "microwebsiteOrderManagerMenuOpen",
      },
      { path: "/pickMyWork-users", state: "miscellaneousMenuOpen" },
      { path: "/invoices", state: "miscellaneousMenuOpen" },
      { path: "/live-user", state: "miscellaneousMenuOpen" },
      { path: "/events", state: "miscellaneousMenuOpen" },
      { path: "/flag", state: "miscellaneousMenuOpen" },
      { path: "/seller", state: "sellerMenuOpen" },
      { path: "/vendors", state: "sellerMenuOpen" },
      { path: "/buyer/StoreManager", state: "storeMenuOpen" },
      { path: "/ondc-seller", state: "sellerMenuOpen" },
      { path: "/customer-list-seller", state: "sellerMenuOpen" },
      { path: "/buyers-address", state: "sellerMenuOpen" },
      { path: "/buyer-user-manager", state: "sellersMenuOpen" },
      { path: "/tabe-buyers", state: "sellersMenuOpen" },
      { path: "/tabe-ambassadors", state: "sellersMenuOpen" },
      { path: "/orders", state: "sellerOrderMenuOpen" },
      { path: "/longdistance", state: "sellerOrderMenuOpen" },
      { path: "/customer-manager", state: "buyerMenuOpen" },
      { path: "/influencers", state: "buyerMenuOpen" },
      { path: "/view-more", state: "viewMoreMenuOpen" },
      { path: "/videos", state: "viewMoreMenuOpen" },
      { path: "/invoice-sellers", state: "viewMoreMenuOpen" },
      { path: "/master-catalogues", state: "viewMoreMenuOpen" },
      { path: "/coin-manager", state: "viewMoreMenuOpen" },
      { path: "/url-generator", state: "viewMoreMenuOpen" },
      { path: "/ondc-seller-subcription", state: "viewMoreMenuOpen" },
      { path: "/ondc-order-manager", state: "ondcManagerMenuOpen" },
      { path: "/ondc-pending-order", state: "ondcManagerMenuOpen" },
      { path: "/ondc-settlement", state: "ondcManagerMenuOpen" },
      { path: "/rsp-dashboard", state: "ondcManagerMenuOpen" },
      { path: "/igm-dashboard", state: "ondcManagerMenuOpen" },
      { path: "/ondc-offers", state: "ondcManagerMenuOpen" },
      { path: "/ondc-return", state: "ondcManagerMenuOpen" },
      { path: "/catalogues", state: "catalogManagerMenuOpen" },
      { path: "/price-catalogues", state: "catalogManagerMenuOpen" },
      { path: "/buyer/Rejection-Catalogue", state: "catalogManagerMenuOpen" },
      { path: "/buyer/Catalogue", state: "catalogManagerMenuOpen" },
      {path:"/buyer/carousel-manager", state: "settingManagerOpen"},
      {path: "/app-settings", state: "settingManagerOpen"},
      { path: "/buyer-ondc-order-manager", state: "BuyerOrderManagerOpen" },
      { path: "/buyer-ondc-pending-order", state: "BuyerOrderManagerOpen" },
      { path: "/buyer-error", state: "BuyerOrderManagerOpen" },
      { path: "/buyer-igm", state: "BuyerOrderManagerOpen" },
      { path: "/coupon-manager", state: "BuyerOrderManagerOpen" },
      { path: "/buyer/ondc-settlements", state: "BuyerOrderManagerOpen" },
      // { path: '/buyer/payment-data', state: 'BuyerOrderManagerOpen' },
      { path: "/buyer-user-manager", state: "BuyerUserManagerOpen" },
      { path: "/sellerOnboard-form", state: "SNPManagerOpen" },
      { path: "/snp-manager", state: "SNPManagerOpen" },
      { path: "/stores", state: "storeMenuOpen" },
      { path: "/ondc-settlement-grocery", state: "settlementMenuOpen" },
      { path: "/ondc-settlement-f&b", state: "settlementMenuOpen" },
      { path: "/rsf-settle-requests", state: "rsfDashBoardOpen" },
      { path: "/rsf-settled-requests", state: "rsfDashBoardOpen" },
      {
        path: "/rsf-settled-orders-details/:transactionId",
        state: "rsfDashBoardOpen",
      },
      { path: "/rsf-log-manager", state: "rsfDashBoardOpen" },
      { path: "/buyer-rsf-settle-requests", state: "buyerRsfDashBoardOpen" },
      { path: "/buyer-rsf-settled-requests", state: "buyerRsfDashBoardOpen" },
      { path: "/buyer-rsf-logs-manager", state: "buyerRsfDashBoardOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }
  render() {
    let authData = JSON.parse(localStorage.getItem("auth"));
    const loginUserRole = get(authData, "role", "");
    const loginUserName = get(authData, "data.userName", "");
    return (
      <nav
        className="sidebar sidebar-offcanvas float-left sidebar_fix"
        id="sidebar"
      >
        <div className="text-center sidebar-brand-wrapper sidebar-fixed fixed-logo kiko-navbar">
          <img alt="" src={favicon} />
          <img alt="" src={logolarge} className="kiko-img" />
        </div>
        <ul className="nav">
          {loginUserRole !== "marketinguser" &&
            loginUserRole !== "buyerSuperAdmin" &&
            loginUserRole !== "bppId" &&
            loginUserRole !== "phonePaytester" &&
            loginUserRole !== "callcenter" &&
            loginUserRole !== "marketing-lead" &&
            loginUserRole !== "ondcOrderManager" && (
              // <li
              //   className={
              //     this.isPathActive('/catalogues') ? 'nav-item active' : 'nav-item'
              //   }
              // >
              //   <Link className='nav-link' to='/catalogues'>
              //     <img alt="" className='sidebar_icon' src={marketingmanager} />
              //     <span className='menu-title'>Catalogue Manager</span>
              //   </Link>
              // </li>
              <li
                className={
                  this.isPathActive("/error-pages")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <div
                  className={
                    this.state.catalogManagerMenuOpen
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() => this.toggleMenuState("catalogManagerMenuOpen")}
                  data-toggle="collapse"
                >
                  <img alt="" className="sidebar_icon" src={walletmanager} />
                  <span className="menu-title">Catalogue Manager</span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse in={this.state.catalogManagerMenuOpen}>
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/catalogues")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/catalogues"
                      >
                        Catalogue Manager
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/price-catalogues")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/price-catalogues"
                      >
                        EAN Price Update
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </li>
            )}
          {loginUserRole !== "marketinguser" &&
            loginUserRole !== "buyerSuperAdmin" &&
            loginUserRole !== "bppId" &&
            loginUserRole !== "phonePaytester" &&
            loginUserRole !== "callcenter" &&
            loginUserRole !== "marketing-lead" &&
            loginUserRole !== "ondcOrderManager" && (
              <li
                className={
                  this.isPathActive("/ondc-orders-admin")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/ondc-orders-admin">
                  <img alt="" className="sidebar_icon" src={marketingmanager} />
                  <span className="menu-title">Ondc Orders </span>
                </Link>
              </li>
            )}
          {loginUserRole !== "marketinguser" &&
            loginUserRole !== "buyerSuperAdmin" &&
            loginUserRole !== "bppId" &&
            loginUserRole !== "phonePaytester" &&
            loginUserRole !== "callcenter" &&
            loginUserRole !== "marketing-lead" &&
            loginUserRole !== "ondcOrderManager" && (
              <li
                className={
                  this.isPathActive("/logistic-orders-admin")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/logistic-orders-admin">
                  <img alt="" className="sidebar_icon" src={marketingmanager} />
                  <span className="menu-title">On Network LSP Orders </span>
                </Link>
              </li>
            )}
          {loginUserRole !== "marketinguser" &&
            loginUserRole !== "buyerSuperAdmin" &&
            loginUserRole !== "bppId" &&
            loginUserRole !== "ondctester" &&
            loginUserRole !== "PATANOPS" &&
            loginUserRole !== "phonePaytester" &&
            loginUserRole !== "marketing-lead" &&
            loginUserRole !== "ondcOrderManager" &&
            loginUserRole !== "ondcOrderManager" && (
              <li
                className={
                  this.isPathActive("/error-pages")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <div
                  className={
                    this.state.itcB2bLogisticsMenuOpen
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() =>
                    this.toggleMenuState("itcB2bLogisticsMenuOpen")
                  }
                  data-toggle="collapse"
                >
                  <img alt="" className="sidebar_icon" src={videomanager} />
                  <span className="menu-title">ITC B2B Logistics</span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse in={this.state.itcB2bLogisticsMenuOpen}>
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/b2b-logistic-orders-admin")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/b2b-logistic-orders-admin"
                      >
                        B2B Logistics Orders
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/itc-b2b-orders")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/itc-b2b-orders"
                      >
                        ITC B2B Orders
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/itc-b2b-logs-manager")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/itc-b2b-logs-manager"
                      >
                        ITC B2B Logs Manager
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/itc-b2b-users")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/itc-b2b-users"
                      >
                        ITC B2B Users
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </li>
            )}
          {loginUserRole !== "marketinguser" &&
            loginUserRole !== "buyerSuperAdmin" &&
            loginUserRole !== "bppId" &&
            loginUserRole !== "callcenter" &&
            loginUserRole !== "ondctester" &&
            loginUserRole !== "PATANOPS" &&
            loginUserRole !== "phonePaytester" &&
            loginUserRole !== "marketing-lead" &&
            loginUserRole !== "ondcOrderManager" && (
              <li
                className={
                  this.isPathActive("/dashboard")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/dashboard">
                  <img alt="" className="sidebar_icon" src={dashboard} />
                  <span className="menu-title">Dashboard</span>
                </Link>
              </li>
            )}
          {loginUserRole !== "marketinguser" &&
            loginUserRole !== "buyerSuperAdmin" &&
            loginUserRole !== "bppId" &&
            loginUserRole !== "ondctester" &&
            loginUserRole !== "PATANOPS" &&
            loginUserRole !== "phonePaytester" &&
            loginUserRole !== "marketing-lead" &&
            loginUserRole !== "ondcOrderManager" &&
            loginUserRole !== "ondcOrderManager" && (
              <li
                className={
                  this.isPathActive("/error-pages")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <div
                  className={
                    this.state.deliveryManagerMenuOpen
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() =>
                    this.toggleMenuState("deliveryManagerMenuOpen")
                  }
                  data-toggle="collapse"
                >
                  <img alt="" className="sidebar_icon" src={videomanager} />
                  <span className="menu-title">Delivery Manager</span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse in={this.state.deliveryManagerMenuOpen}>
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/delivery-manager")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/delivery-manager"
                      >
                        Delivery Manager
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/delivery-data")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/delivery-data"
                      >
                        Delivery Data
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </li>
            )}
          {loginUserRole !== "marketing-lead" &&
            loginUserRole !== "buyerSuperAdmin" &&
            loginUserRole !== "bppId" &&
            loginUserRole !== "ondctester" &&
            loginUserRole !== "PATANOPS" &&
            loginUserRole !== "phonePaytester" &&
            loginUserRole !== "ondcOrderManager" && (
              <li
                className={
                  this.isPathActive("/orders") ? "nav-item active" : "nav-item"
                }
              >
                <div
                  className={
                    this.state.orderManagerOpen
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() => this.toggleMenuState("orderManagerOpen")}
                  data-toggle="collapse"
                >
                  <img alt="" className="sidebar_icon" src={ordermanager} />
                  <span className="menu-title">
                    Kiko Delivery
                    <br /> Order Manager
                  </span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse
                  in={
                    this.state.orderManagerOpen ||
                    this.state.sellerOrderMenuOpen
                  }
                >
                  <ul className="nav flex-column sub-menu">
                    <li
                      className={
                        this.isPathActive("/orders")
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <div
                        className={
                          this.state.sellerOrderMenuOpen
                            ? "nav-link menu-expanded"
                            : "nav-link"
                        }
                        onClick={() =>
                          this.toggleMenuState("sellerOrderMenuOpen")
                        }
                        data-toggle="collapse"
                      >
                        <span className="menu-title">Seller Orders</span>
                        <i className="menu-arrow"></i>
                      </div>
                      <Collapse in={this.state.sellerOrderMenuOpen}>
                        <ul className="nav flex-column sub-menu">
                          <li className="nav-item">
                            <Link
                              className={
                                this.isPathActive("/orders")
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              to="/orders"
                            >
                              All Seller Orders
                            </Link>
                          </li>
                          {loginUserRole !== "marketinguser" &&
                            loginUserRole !== "ondctester" &&
                            loginUserRole !== "PATANOPS" &&
                            loginUserRole !== "phonePaytester" &&
                            loginUserRole !== "marketing-lead" &&
                            loginUserRole !== "ondcOrderManager" && (
                              <li className="nav-item">
                                <Link
                                  className={
                                    this.isPathActive("/longdistance")
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  to="/longdistance"
                                >
                                  Long Distance orders
                                </Link>
                              </li>
                            )}
                        </ul>
                      </Collapse>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/customer-order-manager")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/customer-order-manager"
                      >
                        Buyer Orders
                      </Link>
                    </li>
                    {loginUserRole !== "marketinguser" &&
                      loginUserRole !== "ondctester" &&
                      loginUserRole !== "PATANOPS" &&
                      loginUserRole !== "phonePaytester" &&
                      loginUserRole !== "callcenter" &&
                      loginUserRole !== "marketing-lead" &&
                      loginUserRole !== "ondcOrderManager" && (
                        <li className="nav-item">
                          <Link
                            className={
                              this.isPathActive("/reward-orders-coupon")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/reward-orders-coupon"
                          >
                            R Coupon Order
                          </Link>
                        </li>
                      )}
                    {loginUserRole !== "marketinguser" &&
                      loginUserRole !== "ondctester" &&
                      loginUserRole !== "PATANOPS" &&
                      loginUserRole !== "phonePaytester" &&
                      loginUserRole !== "callcenter" &&
                      loginUserRole !== "marketing-lead" &&
                      loginUserRole !== "ondcOrderManager" && (
                        <li className="nav-item">
                          <Link
                            className={
                              this.isPathActive("/reward-orders-product")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/reward-orders-product"
                          >
                            R Product Order
                          </Link>
                        </li>
                      )}
                    {loginUserRole !== "marketinguser" &&
                      loginUserRole !== "ondctester" &&
                      loginUserRole !== "PATANOPS" &&
                      loginUserRole !== "phonePaytester" &&
                      loginUserRole !== "callcenter" &&
                      loginUserRole !== "marketing-lead" &&
                      loginUserRole !== "ondcOrderManager" && (
                        <li className="nav-item">
                          <Link
                            className={
                              this.isPathActive("/OrderSearch")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/OrderSearch"
                          >
                            Search Orders
                          </Link>
                        </li>
                      )}
                  </ul>
                </Collapse>
              </li>
            )}
          {(loginUserRole === "admin" || loginUserRole === "superadmin") && (
            <li
              className={
                this.isPathActive("/error-pages")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <div
                className={
                  this.state.microwebsiteOrderManagerMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() =>
                  this.toggleMenuState("microwebsiteOrderManagerMenuOpen")
                }
                data-toggle="collapse"
              >
                <img alt="" className="sidebar_icon" src={sellerrewards} />
                <span className="menu-title">MicroWebsite Manager</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.microwebsiteOrderManagerMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/microwebsite-admin-order")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/microwebsite-admin-order"
                    >
                      Microwebsite Orders
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/microwebsite-order-manager")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/microwebsite-order-manager"
                    >
                      Microwebsite Order Manager
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/microwebsite-settlement")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/microwebsite-settlement"
                    >
                      Microwebsite Settlement
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/logistic-website-orders-admin")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/logistic-website-orders-admin"
                    >
                      On Network LSP Website Orders
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
          )}
          {(loginUserRole === "admin" ||
            loginUserRole === "admin" ||
            loginUserRole === "superadmin" ||
            loginUserRole === "ondctester" ||
            loginUserRole === "PATANOPS" ||
            loginUserRole === "phonePaytester") && (
            <li
              className={
                this.isPathActive("/error-pages")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <div
                className={
                  this.state.ondcManagerMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("ondcManagerMenuOpen")}
                data-toggle="collapse"
              >
                <img alt="" className="sidebar_icon" src={sellerrewards} />
                <span className="menu-title">ONDC Manager</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse
                in={
                  this.state.ondcManagerMenuOpen ||
                  this.state.settlementMenuOpen ||
                  this.state.rsfDashBoardOpen
                }
              >
                <ul className="nav flex-column sub-menu">
                  {(loginUserRole === "admin" ||
                    loginUserRole === "superadmin" ||
                    loginUserRole === "ondctester" ||
                    loginUserRole === "phonePaytester") && (
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/ondc-order-manager")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/ondc-order-manager"
                      >
                        ONDC Order Manager
                      </Link>
                    </li>
                  )}
                  {(loginUserRole === "admin" ||
                    loginUserRole === "superadmin") && (
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/ondc-pending-order")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/ondc-pending-order"
                      >
                        ONDC Pending Order
                      </Link>
                    </li>
                  )}
                  {(loginUserRole === "admin" ||
                    loginUserRole === "superadmin" ||
                    loginUserRole === "ondctester") && (
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/igm-dashboard")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/igm-dashboard"
                      >
                        IGM Dashboard
                      </Link>
                    </li>
                  )}
                  {(loginUserRole === "admin" ||
                    loginUserRole === "superadmin" ||
                    loginUserRole === "ondctester" ||
                    loginUserRole === "PATANOPS") && (
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/ondc-offers")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/ondc-offers"
                      >
                        ONDC Offers Manager
                      </Link>
                    </li>
                  )}
                  {(loginUserRole === "admin" ||
                    loginUserRole === "admin" ||
                    loginUserRole === "superadmin" ||
                    loginUserRole === "ondctester" ||
                    loginUserRole === "PATANOPS" ||
                    loginUserRole === "phonePaytester") && (
                    <li
                      className={
                        this.isPathActive("/error-pages")
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <div
                        className={
                          this.state.rsfDashBoardOpen
                            ? "nav-link menu-expanded"
                            : "nav-link"
                        }
                        onClick={() => this.toggleMenuState("rsfDashBoardOpen")}
                        data-toggle="collapse"
                      >
                        <span className="menu-title">RSF Dashboard</span>
                        <i className="menu-arrow"></i>
                      </div>
                      <Collapse in={this.state.rsfDashBoardOpen}>
                        <ul className="nav flex-column sub-menu">
                          {(loginUserRole === "admin" ||
                            loginUserRole === "superadmin" ||
                            loginUserRole === "ondctester" ||
                            loginUserRole === "phonePaytester") && (
                            <li className="nav-item">
                              <Link
                                className={
                                  this.isPathActive("/rsf-settle-requests")
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                to="/rsf-settle-requests"
                              >
                                RSF Settle Requests
                              </Link>
                            </li>
                          )}
                          {(loginUserRole === "admin" ||
                            loginUserRole === "superadmin" ||
                            loginUserRole === "ondctester" ||
                            loginUserRole === "phonePaytester") && (
                            <li className="nav-item">
                              <Link
                                className={
                                  this.isPathActive("/rsf-settled-requests")
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                to="/rsf-settled-requests"
                              >
                                RSF Settled Orders
                              </Link>
                            </li>
                          )}
                          {(loginUserRole === "admin" ||
                            loginUserRole === "superadmin" ||
                            loginUserRole === "ondctester" ||
                            loginUserRole === "phonePaytester") && (
                            <li className="nav-item">
                              <Link
                                className={
                                  this.isPathActive("/rsf-log-manager")
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                to="/rsf-log-manager"
                              >
                                RSF Log Manager
                              </Link>
                            </li>
                          )}
                        </ul>
                      </Collapse>
                    </li>
                  )}

                  {/* {(loginUserRole === 'admin' || loginUserRole === 'superadmin' || loginUserRole === 'ondctester' || loginUserRole === 'PATANOPS') && <li className='nav-item'>
                    <Link
                      className={
                        this.isPathActive('/ondc-settlement')
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to='/ondc-settlement'
                    >
                      ONDC Settlement
                    </Link>
                  </li>} */}
                  {(loginUserRole === "admin" ||
                    loginUserRole === "superadmin" ||
                    loginUserRole === "ondctester" ||
                    loginUserRole === "PATANOPS") && (
                    <li
                      className={
                        this.isPathActive("") ? "nav-item active" : "nav-item"
                      }
                    >
                      <div
                        className={
                          this.state.settlementMenuOpen
                            ? "nav-link menu-expanded"
                            : "nav-link"
                        }
                        onClick={() =>
                          this.toggleMenuState("settlementMenuOpen")
                        }
                        data-toggle="collapse"
                      >
                        <span className="menu-title">ONDC Settlement</span>
                        <i className="menu-arrow"></i>
                      </div>
                      <Collapse in={this.state.settlementMenuOpen}>
                        <ul className="nav flex-column sub-menu">
                          {(loginUserRole === "admin" ||
                            loginUserRole === "superadmin" ||
                            loginUserRole === "ondctester" ||
                            loginUserRole === "PATANOPS") && (
                            <li className="nav-item">
                              <Link
                                className={
                                  this.isPathActive("/ondc-settlement-grocery")
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                to="/ondc-settlement-grocery"
                              >
                                Grocery Settlements
                              </Link>
                            </li>
                          )}
                          {(loginUserRole === "admin" ||
                            loginUserRole === "superadmin" ||
                            loginUserRole === "ondctester" ||
                            loginUserRole === "PATANOPS") && (
                            <li className="nav-item">
                              <Link
                                className={
                                  this.isPathActive("/ondc-settlement-f&b")
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                to="/ondc-settlement-f&b"
                              >
                                F&B Settlements
                              </Link>
                            </li>
                          )}
                        </ul>
                      </Collapse>
                    </li>
                  )}
                  {(loginUserRole === "admin" ||
                    loginUserRole === "superadmin" ||
                    loginUserRole === "ondctester" ||
                    loginUserRole === "PATANOPS") && (
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/ondc-return")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/ondc-return"
                      >
                        ONDC Return
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}

          {/* {(loginUserRole === 'admin' || loginUserRole === 'admin' || loginUserRole === 'superadmin' || loginUserRole === 'ondctester' || loginUserRole === 'PATANOPS' || loginUserRole === 'phonePaytester') &&
            <li
              className={
                this.isPathActive('/error-pages') ? 'nav-item active' : 'nav-item'
              }
            >
              <div
                className={
                  this.state.rsfDashBoardOpen
                    ? 'nav-link menu-expanded'
                    : 'nav-link'
                }
                onClick={() => this.toggleMenuState('rsfDashBoardOpen')}
                data-toggle='collapse'
              >
                <img alt="" className='sidebar_icon' src={sellerrewards} />
                <span className='menu-title'>RSF Dashboard</span>
                <i className='menu-arrow'></i>
              </div>
              <Collapse in={this.state.rsfDashBoardOpen || this.state.rsfDashBoardOpen}>
                <ul className='nav flex-column sub-menu'>
                  {(loginUserRole === 'admin' || loginUserRole === 'superadmin' || loginUserRole === 'ondctester' || loginUserRole === 'phonePaytester') &&
                    <li className='nav-item'>
                      <Link
                        className={
                          this.isPathActive('/rsf-settle-requests')
                            ? 'nav-link active'
                            : 'nav-link'
                        }
                        to='rsf-settle-requests'
                      >
                        RSF Settle Requests
                      </Link>
                    </li>}
                  {(loginUserRole === 'admin' || loginUserRole === 'superadmin' || loginUserRole === 'ondctester' || loginUserRole === 'phonePaytester') &&
                    <li className='nav-item'>
                      <Link
                        className={
                          this.isPathActive('/rsf-settled-requests')
                            ? 'nav-link active'
                            : 'nav-link'
                        }
                        to='rsf-settled-requests'
                      >
                        RSF Settled Orders
                      </Link>
                    </li>}

                </ul>
              </Collapse>
            </li>
          } */}

          {loginUserRole !== "marketinguser" &&
            loginUserRole !== "buyerSuperAdmin" &&
            loginUserRole !== "bppId" &&
            loginUserRole !== "callcenter" &&
            loginUserRole !== "ondctester" &&
            loginUserRole !== "PATANOPS" &&
            loginUserRole !== "phonePaytester" &&
            loginUserRole !== "marketing-lead" &&
            loginUserRole !== "ondcOrderManager" && (
              <li
                className={
                  this.isPathActive("/report-manager")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/report-manager">
                  <img alt="" className="sidebar_icon" src={dashboard} />
                  <span className="menu-title">Report Manager</span>
                </Link>
              </li>
            )}
          {loginUserRole !== "marketinguser" &&
            loginUserRole !== "buyerSuperAdmin" &&
            loginUserRole !== "bppId" &&
            loginUserRole !== "callcenter" &&
            loginUserRole !== "ondctester" &&
            loginUserRole !== "PATANOPS" &&
            loginUserRole !== "phonePaytester" &&
            loginUserRole !== "marketing-lead" &&
            loginUserRole !== "ondcOrderManager" && (
              <li
                className={
                  this.isPathActive("/log-manager")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/log-manager">
                  <img alt="" className="sidebar_icon" src={dashboard} />
                  <span className="menu-title">Logs Manager</span>
                </Link>
              </li>
            )}
          {loginUserRole !== "marketinguser" &&
            loginUserRole !== "buyerSuperAdmin" &&
            loginUserRole !== "bppId" &&
            loginUserRole !== "callcenter" &&
            loginUserRole !== "ondctester" &&
            loginUserRole !== "PATANOPS" &&
            loginUserRole !== "phonePaytester" &&
            loginUserRole !== "marketing-lead" &&
            loginUserRole !== "ondcOrderManager" && (
              <li
                className={
                  this.isPathActive("/communication-manager")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/communication-manager">
                  <img alt="" className="sidebar_icon" src={dashboard} />
                  <span className="menu-title">Communication Manager</span>
                </Link>
              </li>
            )}
          {loginUserRole !== "callcenter" &&
            loginUserRole !== "buyerSuperAdmin" &&
            loginUserRole !== "bppId" &&
            loginUserRole !== "ondctester" &&
            loginUserRole !== "PATANOPS" &&
            loginUserRole !== "phonePaytester" &&
            loginUserRole !== "marketing-lead" &&
            loginUserRole !== "ondcOrderManager" && (
              <li
                className={
                  this.isPathActive("/settings")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/settings">
                  <img alt="" className="sidebar_icon" src={settingmanager} />
                  <span className="menu-title">Setting Manager</span>
                </Link>
              </li>
            )}

          {loginUserRole !== "marketing-lead" &&
            loginUserRole !== "buyerSuperAdmin" &&
            loginUserRole !== "bppId" &&
            loginUserRole !== "phonePaytester" &&
            loginUserRole !== "ondcOrderManager" && (
              <li
                className={
                  this.isPathActive("/users") ? "nav-item active" : "nav-item"
                }
              >
                <div
                  className={
                    this.state.userManagerMenuOpen
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() => this.toggleMenuState("userManagerMenuOpen")}
                  data-toggle="collapse"
                >
                  <img alt="" className="sidebar_icon" src={usermanager} />
                  <span className="menu-title">User Manager</span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse
                  in={
                    this.state.userManagerMenuOpen ||
                    this.state.sellerMenuOpen ||
                    this.state.buyerMenuOpen
                  }
                >
                  <ul className="nav flex-column sub-menu">
                    {loginUserRole !== "marketinguser" && (
                      <li
                        className={
                          this.isPathActive("/seller")
                            ? "nav-item active"
                            : "nav-item"
                        }
                      >
                        <div
                          className={
                            this.state.sellerMenuOpen
                              ? "nav-link menu-expanded"
                              : "nav-link"
                          }
                          onClick={() => this.toggleMenuState("sellerMenuOpen")}
                          data-toggle="collapse"
                        >
                          <span className="menu-title">Seller</span>
                          <i className="menu-arrow"></i>
                        </div>
                        <Collapse in={this.state.sellerMenuOpen}>
                          <ul className="nav flex-column sub-menu">
                            {loginUserRole !== "marketinguser" &&
                              loginUserName !== "ONDCOPS" && (
                                <li className="nav-item sub_na_item">
                                  <Link
                                    className={
                                      this.isPathActive("/seller")
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    to="/seller"
                                  >
                                    All Sellers
                                  </Link>
                                </li>
                              )}
                            {loginUserRole !== "marketinguser" &&
                              loginUserRole !== "marketing-lead" &&
                              loginUserRole !== "ondcOrderManager" && (
                                <li className="nav-item sub_na_item">
                                  <Link
                                    className={
                                      this.isPathActive("/ondc-seller")
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    to="/ondc-seller"
                                  >
                                    Ondc Seller
                                  </Link>
                                </li>
                              )}
                            {loginUserRole !== "marketinguser" &&
                              loginUserRole !== "ondctester" &&
                              loginUserRole !== "PATANOPS" &&
                              loginUserRole !== "marketing-lead" &&
                              loginUserRole !== "ondcOrderManager" && (
                                <li className="nav-item">
                                  <Link
                                    className={
                                      this.isPathActive("/vendors")
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    to="/vendors"
                                  >
                                    Verified Sellers
                                  </Link>
                                </li>
                              )}
                            {loginUserRole !== "ondctester" &&
                              loginUserRole !== "PATANOPS" && (
                                <li className="nav-item">
                                  <Link
                                    className={
                                      this.isPathActive("/customer-list-seller")
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    to="/customer-list-seller"
                                  >
                                    Buyer Listed Sellers
                                  </Link>
                                </li>
                              )}
                            {loginUserRole !== "callcenter" &&
                              loginUserRole !== "ondctester" &&
                              loginUserRole !== "PATANOPS" &&
                              loginUserRole !== "marketing-lead" &&
                              loginUserRole !== "ondcOrderManager" && (
                                <li className="nav-item">
                                  <Link
                                    className={
                                      this.isPathActive("/buyers-address")
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    to="/buyers-address"
                                  >
                                    Buyer Address
                                  </Link>
                                </li>
                              )}
                          </ul>
                        </Collapse>
                      </li>
                    )}
                    {loginUserRole !== "marketinguser" &&
                      loginUserRole !== "ondctester" &&
                      loginUserRole !== "PATANOPS" && (
                        <li
                          className={
                            this.isPathActive("")
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <div
                            className={
                              this.state.buyerMenuOpen
                                ? "nav-link menu-expanded"
                                : "nav-link"
                            }
                            onClick={() =>
                              this.toggleMenuState("buyerMenuOpen")
                            }
                            data-toggle="collapse"
                          >
                            <span className="menu-title">Buyers</span>
                            <i className="menu-arrow"></i>
                          </div>
                          <Collapse in={this.state.buyerMenuOpen}>
                            <ul className="nav flex-column sub-menu">
                              {loginUserRole !== "marketinguser" &&
                                loginUserRole !== "ondctester" &&
                                loginUserRole !== "PATANOPS" &&
                                loginUserRole !== "phonePaytester" &&
                                loginUserRole !== "marketing-lead" &&
                                loginUserRole !== "ondcOrderManager" && (
                                  <li className="nav-item">
                                    <Link
                                      className={
                                        this.isPathActive("/customer-manager")
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      to="/customer-manager"
                                    >
                                      All Buyers
                                    </Link>
                                  </li>
                                )}
                              {loginUserRole !== "marketinguser" &&
                                loginUserRole !== "ondctester" &&
                                loginUserRole !== "PATANOPS" &&
                                loginUserRole !== "phonePaytester" &&
                                loginUserRole !== "callcenter" &&
                                loginUserRole !== "marketing-lead" &&
                                loginUserRole !== "ondcOrderManager" && (
                                  <li className="nav-item">
                                    <Link
                                      className={
                                        this.isPathActive("/influencers")
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      to="/influencers"
                                    >
                                      Influencers
                                    </Link>
                                  </li>
                                )}
                            </ul>
                          </Collapse>
                        </li>
                      )}
                    {loginUserRole !== "marketinguser" &&
                      loginUserRole !== "ondctester" &&
                      loginUserRole !== "PATANOPS" &&
                      loginUserRole !== "phonePaytester" &&
                      loginUserRole !== "marketing-lead" &&
                      loginUserRole !== "ondcOrderManager" && (
                        <li className="nav-item">
                          <Link
                            className={
                              this.isPathActive("/delete-accounts")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/delete-accounts"
                          >
                            Deleted Users
                          </Link>
                        </li>
                      )}
                    {loginUserRole !== "callcenter" &&
                      loginUserRole !== "ondctester" &&
                      loginUserRole !== "PATANOPS" &&
                      loginUserRole !== "marketing-lead" &&
                      loginUserRole !== "ondcOrderManager" && (
                        <li className="nav-item">
                          <Link
                            className={
                              this.isPathActive("/search-users")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/search-users"
                          >
                            Search Users
                          </Link>
                        </li>
                      )}
                  </ul>
                </Collapse>
              </li>
            )}
          {loginUserRole !== "marketinguser" &&
            loginUserRole !== "buyerSuperAdmin" &&
            loginUserRole !== "bppId" &&
            loginUserRole !== "ondctester" &&
            loginUserRole !== "PATANOPS" &&
            loginUserRole !== "phonePaytester" &&
            loginUserRole !== "marketing-lead" &&
            loginUserRole !== "ondcOrderManager" && (
              <li
                className={
                  this.isPathActive("/error-pages")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <div
                  className={
                    this.state.walletManagerMenuOpen
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() => this.toggleMenuState("walletManagerMenuOpen")}
                  data-toggle="collapse"
                >
                  <img alt="" className="sidebar_icon" src={walletmanager} />
                  <span className="menu-title">Wallet Manager</span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse in={this.state.walletManagerMenuOpen}>
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/vendor-wallet")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/vendor-wallet"
                      >
                        Vendor Wallet
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/offer-wallet")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/offer-wallet"
                      >
                        Offer Wallet
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </li>
            )}
          {loginUserRole !== "marketing-lead" &&
            loginUserRole !== "buyerSuperAdmin" &&
            loginUserRole !== "bppId" &&
            loginUserRole !== "phonePaytester" &&
            loginUserRole !== "ondcOrderManager" &&
            loginUserName !== "ITCADMIN" && (
              <li
                className={
                  this.isPathActive("/view-more")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <div
                  className={
                    this.state.viewMoreMenuOpen
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() => this.toggleMenuState("viewMoreMenuOpen")}
                  data-toggle="collapse"
                >
                  <img alt="" className="sidebar_icon" src={usermanager} />
                  <span className="menu-title">View More</span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse
                  in={
                    this.state.viewMoreMenuOpen ||
                    this.state.miscellaneousMenuOpen ||
                    this.state.accountManagerMenuOpen ||
                    this.state.gstManagerMenuOpen ||
                    this.state.productManagerMenuOpen ||
                    this.state.sellerRewardMenuOpen ||
                    this.state.buddyRewardMenuOpen ||
                    this.state.supportManagerMenuOpen
                  }
                >
                  <ul className="nav flex-column sub-menu">
                    {loginUserRole !== "marketinguser" &&
                      loginUserRole !== "ondctester" &&
                      loginUserRole !== "PATANOPS" &&
                      loginUserRole !== "phonePaytester" &&
                      loginUserRole !== "marketing-lead" &&
                      loginUserRole !== "ondcOrderManager" && (
                        <li
                          className={
                            this.isPathActive("/master-catalogues")
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <Link className="nav-link" to="/master-catalogues">
                            <img
                              alt=""
                              className="sidebar_icon"
                              src={marketingmanager}
                            />
                            <span className="menu-title">
                              Catalog Master
                              <br /> Database
                            </span>
                          </Link>
                        </li>
                      )}
                    {loginUserRole !== "marketinguser" &&
                      loginUserRole !== "ondctester" &&
                      loginUserRole !== "PATANOPS" &&
                      loginUserRole !== "phonePaytester" &&
                      loginUserRole !== "callcenter" &&
                      loginUserRole !== "marketing-lead" &&
                      loginUserRole !== "ondcOrderManager" && (
                        <li
                          className={
                            this.isPathActive("/accountManagerMenuOpen")
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <div
                            className={
                              this.state.accountManagerMenuOpen
                                ? "nav-link menu-expanded"
                                : "nav-link"
                            }
                            onClick={() =>
                              this.toggleMenuState("accountManagerMenuOpen")
                            }
                            data-toggle="collapse"
                          >
                            <img
                              alt=""
                              className="sidebar_icon"
                              src={accountmanager}
                            />
                            <span className="menu-title">Accounts Manager</span>
                            <i className="menu-arrow"></i>
                          </div>
                          <Collapse in={this.state.accountManagerMenuOpen}>
                            <ul className="nav flex-column sub-menu">
                              <li className="nav-item sub_na_item">
                                <Link
                                  className={
                                    this.isPathActive("/video-call-orders")
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  to="/video-call-orders"
                                >
                                  Video Call Order
                                </Link>
                              </li>
                              <li className="nav-item sub_na_item">
                                <Link
                                  className={
                                    this.isPathActive("/withdrawal-manager")
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  to="/withdrawal-manager"
                                >
                                  Withdrawals
                                </Link>
                              </li>
                              <li className="nav-item sub_na_item">
                                <Link
                                  className={
                                    this.isPathActive("/NAC-kikopayment")
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  to="/NAC-kikopayment"
                                >
                                  N A/C KIKO PAYMENTS
                                </Link>
                              </li>
                            </ul>
                          </Collapse>
                        </li>
                      )}
                    {loginUserRole !== "marketinguser" &&
                      loginUserRole !== "ondctester" &&
                      loginUserRole !== "PATANOPS" &&
                      loginUserRole !== "phonePaytester" &&
                      loginUserRole !== "callcenter" &&
                      loginUserRole !== "marketing-lead" &&
                      loginUserRole !== "ondcOrderManager" && (
                        <li
                          className={
                            this.isPathActive("/products")
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <div
                            className={
                              this.state.productManagerMenuOpen
                                ? "nav-link menu-expanded"
                                : "nav-link"
                            }
                            onClick={() =>
                              this.toggleMenuState("productManagerMenuOpen")
                            }
                            data-toggle="collapse"
                          >
                            <img
                              alt=""
                              className="sidebar_icon"
                              src={productmanager}
                            />
                            <span className="menu-title">Product Manager</span>
                            <i className="menu-arrow"></i>
                          </div>
                          <Collapse in={this.state.productManagerMenuOpen}>
                            <ul className="nav flex-column sub-menu">
                              <li className="nav-item sub_na_item">
                                <Link
                                  className={
                                    this.isPathActive("/category-manager")
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  to="/category-manager"
                                >
                                  Categories
                                </Link>
                              </li>
                              <li className="nav-item sub_na_item">
                                <Link
                                  className={
                                    this.isPathActive("/reward-manager")
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  to="/reward-manager"
                                >
                                  Reward Products
                                </Link>
                              </li>
                            </ul>
                          </Collapse>
                        </li>
                      )}
                    {loginUserRole !== "marketinguser" &&
                      loginUserRole !== "ondctester" &&
                      loginUserRole !== "PATANOPS" &&
                      loginUserRole !== "phonePaytester" &&
                      loginUserRole !== "callcenter" &&
                      loginUserRole !== "marketing-lead" &&
                      loginUserRole !== "ondcOrderManager" && (
                        <li
                          className={
                            this.isPathActive("/error-pages")
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <div
                            className={
                              this.state.sellerRewardMenuOpen
                                ? "nav-link menu-expanded"
                                : "nav-link"
                            }
                            onClick={() =>
                              this.toggleMenuState("sellerRewardMenuOpen")
                            }
                            data-toggle="collapse"
                          >
                            <img
                              alt=""
                              className="sidebar_icon"
                              src={sellerrewards}
                            />
                            <span className="menu-title">Seller Reward</span>
                            <i className="menu-arrow"></i>
                          </div>
                          <Collapse in={this.state.sellerRewardMenuOpen}>
                            <ul className="nav flex-column sub-menu">
                              <li className="nav-item sub_na_item">
                                <Link
                                  className={
                                    this.isPathActive("/reward-seller")
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  to="/reward-seller"
                                >
                                  Earn History
                                </Link>
                              </li>
                              <li className="nav-item sub_na_item">
                                <Link
                                  className={
                                    this.isPathActive("/Withdrawal-seller")
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  to="/Withdrawal-seller"
                                >
                                  Reward Withdrawal
                                </Link>
                              </li>
                            </ul>
                          </Collapse>
                        </li>
                      )}
                    {loginUserRole !== "marketinguser" &&
                      loginUserRole !== "ondctester" &&
                      loginUserRole !== "PATANOPS" &&
                      loginUserRole !== "phonePaytester" &&
                      loginUserRole !== "callcenter" &&
                      loginUserRole !== "marketing-lead" &&
                      loginUserRole !== "ondcOrderManager" && (
                        <li
                          className={
                            this.isPathActive("/error-pages")
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <div
                            className={
                              this.state.buddyRewardMenuOpen
                                ? "nav-link menu-expanded"
                                : "nav-link"
                            }
                            onClick={() =>
                              this.toggleMenuState("buddyRewardMenuOpen")
                            }
                            data-toggle="collapse"
                          >
                            <img
                              alt=""
                              className="sidebar_icon"
                              src={sellerrewards}
                            />
                            <span className="menu-title">Buddy Reward</span>
                            <i className="menu-arrow"></i>
                          </div>
                          <Collapse in={this.state.buddyRewardMenuOpen}>
                            <ul className="nav flex-column sub-menu">
                              <li className="nav-item sub_na_item">
                                <Link
                                  className={
                                    this.isPathActive("/buddy-reward")
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  to="/buddy-reward"
                                >
                                  Earn History
                                </Link>
                              </li>
                              <li className="nav-item sub_na_item">
                                <Link
                                  className={
                                    this.isPathActive("/buddy-Withdrawal")
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  to="/buddy-Withdrawal"
                                >
                                  Reward Withdrawal
                                </Link>
                              </li>
                            </ul>
                          </Collapse>
                        </li>
                      )}
                    {loginUserRole !== "marketinguser" &&
                      loginUserRole !== "phonePaytester" &&
                      loginUserRole !== "marketing-lead" &&
                      loginUserRole !== "ondcOrderManager" && (
                        <li
                          className={
                            this.isPathActive("/error-pages")
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <div
                            className={
                              this.state.supportManagerMenuOpen
                                ? "nav-link menu-expanded"
                                : "nav-link"
                            }
                            onClick={() =>
                              this.toggleMenuState("supportManagerMenuOpen")
                            }
                            data-toggle="collapse"
                          >
                            <img
                              alt=""
                              className="sidebar_icon"
                              src={supportmanager}
                            />
                            <span className="menu-title">Support Manager</span>
                            <i className="menu-arrow"></i>
                          </div>
                          <Collapse in={this.state.supportManagerMenuOpen}>
                            <ul className="nav flex-column sub-menu">
                              <li className="nav-item sub_na_item">
                                <Link
                                  className={
                                    this.isPathActive("/call-enquiry")
                                      ? "nav-link active"
                                      : "nav-link sub_na_item"
                                  }
                                  to="/call-enquiry"
                                >
                                  Call Enquiry
                                </Link>
                              </li>
                            </ul>
                          </Collapse>
                        </li>
                      )}
                    {loginUserRole !== "marketinguser" &&
                      loginUserRole !== "ondctester" &&
                      loginUserRole !== "PATANOPS" &&
                      loginUserRole !== "phonePaytester" &&
                      loginUserRole !== "marketing-lead" &&
                      loginUserRole !== "ondcOrderManager" &&
                      loginUserRole !== "callcenter" && (
                        <li
                          className={
                            this.isPathActive("/error-pages")
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <div
                            className={
                              this.state.gstManagerMenuOpen
                                ? "nav-link menu-expanded"
                                : "nav-link"
                            }
                            onClick={() =>
                              this.toggleMenuState("gstManagerMenuOpen")
                            }
                            data-toggle="collapse"
                          >
                            <img
                              alt=""
                              className="sidebar_icon"
                              src={walletmanager}
                            />
                            <span className="menu-title">GST Manager</span>
                            <i className="menu-arrow"></i>
                          </div>
                          <Collapse in={this.state.gstManagerMenuOpen}>
                            <ul className="nav flex-column sub-menu">
                              <li className="nav-item sub_na_item">
                                <Link
                                  className={
                                    this.isPathActive("/selers-orders")
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  to="/selers-orders"
                                >
                                  Seller Orders
                                </Link>
                              </li>
                              <li className="nav-item sub_na_item">
                                <Link
                                  className={
                                    this.isPathActive("/buyer-orders")
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  to="/buyer-orders"
                                >
                                  Buyer Orders
                                </Link>
                              </li>
                              <li className="nav-item sub_na_item">
                                <Link
                                  className={
                                    this.isPathActive("/ondc-gst-manager")
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  to="/ondc-gst-manager"
                                >
                                  Ondc Gst Manager
                                </Link>
                              </li>
                              {/* <li className='nav-item sub_na_item'>
                            <Link
                              className={
                                this.isPathActive('/ondc-seller-subcription')
                                  ? 'nav-link active'
                                  : 'nav-link'
                              }
                              to='/ondc-seller-subcription'
                            >
                              Seller Subcription
                            </Link>
                          </li> */}
                            </ul>
                          </Collapse>
                        </li>
                      )}
                    {loginUserRole !== "marketinguser" &&
                      loginUserRole !== "ondctester" && (
                        <li
                          className={
                            this.isPathActive("/ondc-seller-subcription")
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <Link
                            className="nav-link"
                            to="/ondc-seller-subcription"
                          >
                            <img
                              alt=""
                              className="sidebar_icon"
                              src={walletmanager}
                            />
                            <span className="menu-title">
                              Seller Subcription
                            </span>
                          </Link>
                        </li>
                      )}
                    {((loginUserRole === "marketing-lead" &&
                      loginUserName === "taskbucks") ||
                      loginUserRole === "superadmin") && (
                      <li
                        className={
                          this.isPathActive("/taskbucks-buyers")
                            ? "nav-item active"
                            : "nav-item"
                        }
                      >
                        <div
                          className={
                            this.state.miscellaneousMenuOpen
                              ? "nav-link menu-expanded"
                              : "nav-link"
                          }
                          onClick={() =>
                            this.toggleMenuState("miscellaneousMenuOpen")
                          }
                          data-toggle="collapse"
                        >
                          <img
                            alt=""
                            className="sidebar_icon"
                            src={settingmanager}
                          />
                          <span className="menu-title">Miscellaneous</span>
                          <i className="menu-arrow"></i>
                        </div>
                        <Collapse in={this.state.miscellaneousMenuOpen}>
                          <ul className="nav flex-column sub-menu">
                            <li className="nav-item sub_na_item">
                              <Link
                                className={
                                  this.isPathActive("/taskbucks-buyers")
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                to="/taskbucks-buyers"
                              >
                                Taskbucks
                              </Link>
                            </li>
                            <li className="nav-item sub_na_item">
                              <Link
                                className={
                                  this.isPathActive("/pickMyWork-users")
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                to="/pickMyWork-users"
                              >
                                PickMyWork
                              </Link>
                            </li>
                            {loginUserRole !== "marketinguser" &&
                              loginUserRole !== "ondctester" &&
                              loginUserRole !== "PATANOPS" &&
                              loginUserRole !== "phonePaytester" &&
                              loginUserRole !== "marketing-lead" &&
                              loginUserRole !== "ondcOrderManager" && (
                                <li className="nav-item sub_na_item">
                                  <Link
                                    className={
                                      this.isPathActive("/invoices")
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    to="/invoices"
                                  >
                                    Payment Link
                                  </Link>
                                </li>
                              )}
                            {loginUserRole !== "marketinguser" &&
                              loginUserRole !== "ondctester" &&
                              loginUserRole !== "PATANOPS" &&
                              loginUserRole !== "phonePaytester" &&
                              loginUserRole !== "marketing-lead" &&
                              loginUserRole !== "ondcOrderManager" && (
                                <li className="nav-item ">
                                  <Link
                                    className={
                                      this.isPathActive("/events")
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    to="/events"
                                  >
                                    Events Manager
                                  </Link>
                                </li>
                              )}
                            {loginUserRole !== "callcenter" &&
                              loginUserRole !== "marketing-lead" &&
                              loginUserRole !== "ondcOrderManager" && (
                                <li className="nav-item ">
                                  <Link
                                    className={
                                      this.isPathActive("/live-user")
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    to="/live-user"
                                  >
                                    Live Users
                                  </Link>
                                </li>
                              )}
                            {loginUserRole !== "marketinguser" &&
                              loginUserRole !== "ondctester" &&
                              loginUserRole !== "PATANOPS" &&
                              loginUserRole !== "phonePaytester" &&
                              loginUserRole !== "callcenter" &&
                              loginUserRole !== "marketing-lead" &&
                              loginUserRole !== "ondcOrderManager" && (
                                <li className="nav-item ">
                                  <Link
                                    className={
                                      this.isPathActive("/flag")
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    to="/flag"
                                  >
                                    Flag Manager
                                  </Link>
                                </li>
                              )}
                          </ul>
                        </Collapse>
                      </li>
                    )}
                    {loginUserRole !== "marketinguser" &&
                      loginUserRole !== "ondctester" &&
                      loginUserRole !== "PATANOPS" &&
                      loginUserRole !== "phonePaytester" &&
                      loginUserRole !== "marketing-lead" &&
                      loginUserRole !== "ondcOrderManager" && (
                        <li
                          className={
                            this.isPathActive("/invoice-sellers")
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <Link className="nav-link" to="/invoice-sellers">
                            <img
                              alt=""
                              className="sidebar_icon"
                              src={walletmanager}
                            />
                            <span className="menu-title">Seller Invoice</span>
                          </Link>
                        </li>
                      )}
                    {loginUserRole !== "marketinguser" &&
                      loginUserRole !== "ondctester" &&
                      loginUserRole !== "PATANOPS" &&
                      loginUserRole !== "phonePaytester" &&
                      loginUserRole !== "marketing-lead" &&
                      loginUserRole !== "ondcOrderManager" && (
                        <li
                          className={
                            this.isPathActive("/videos")
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <Link className="nav-link" to="/videos">
                            <img
                              alt=""
                              className="sidebar_icon"
                              src={videomanager}
                            />
                            <span className="menu-title">Video Manager</span>
                          </Link>
                        </li>
                      )}
                    {loginUserRole !== "marketinguser" &&
                      loginUserRole !== "ondctester" &&
                      loginUserRole !== "PATANOPS" &&
                      loginUserRole !== "phonePaytester" &&
                      loginUserRole !== "callcenter" &&
                      loginUserRole !== "marketing-lead" &&
                      loginUserRole !== "ondcOrderManager" && (
                        <li
                          className={
                            this.isPathActive("/coin-manager")
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <Link className="nav-link" to="/coin-manager">
                            <img
                              alt=""
                              className="sidebar_icon"
                              src={marketingmanager}
                            />
                            <span className="menu-title">Coin Manager</span>
                          </Link>
                        </li>
                      )}
                    {loginUserRole !== "marketinguser" &&
                      loginUserRole !== "ondctester" &&
                      loginUserRole !== "PATANOPS" &&
                      loginUserRole !== "phonePaytester" &&
                      loginUserRole !== "callcenter" &&
                      loginUserRole !== "marketing-lead" &&
                      loginUserRole !== "ondcOrderManager" && (
                        <li
                          className={
                            this.isPathActive("/url-generator")
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <Link className="nav-link" to="/url-generator">
                            <img
                              alt=""
                              className="sidebar_icon"
                              src={marketingmanager}
                            />
                            <span className="menu-title">Url Generator</span>
                          </Link>
                        </li>
                      )}
                    {/* {loginUserRole !== 'marketinguser' && loginUserRole !== 'ondctester' && loginUserRole !== 'PATANOPS' && loginUserRole !== 'phonePaytester' && loginUserRole !== 'callcenter' && loginUserRole !== 'marketing-lead' && loginUserRole !== 'ondcOrderManager' &&
                    <li
                      className={
                        this.isPathActive('/search') ? 'nav-item active' : 'nav-item'
                      }
                    >
                      <Link className='nav-link' to='/search'>
                        <img alt="" className='sidebar_icon' src={videomanager} />
                        <span className='menu-title'>Search History</span>
                      </Link>
                    </li>
                  } */}
                  </ul>
                </Collapse>
              </li>
            )}
          {loginUserRole === "buyerSuperAdmin" && (
            <li
              className={
                this.isPathActive("/buyer-dashboard")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/buyer-dashboard">
                <img alt="" className="sidebar_icon" src={dashboard} />
                <span className="menu-title">Dashboard</span>
              </Link>
            </li>
          )}
          {(loginUserRole === "buyerSuperAdmin" ||
            loginUserRole === "bppId") && (
            <li
              className={
                this.isPathActive("/error-pages")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <div
                className={
                  this.state.catalogManagerMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("catalogManagerMenuOpen")}
                data-toggle="collapse"
              >
                <img alt="" className="sidebar_icon" src={walletmanager} />
                <span className="menu-title">Catalogue Manager</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.catalogManagerMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/buyer/Catalogue")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/buyer/Catalogue"
                    >
                      Active Catalogue
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/buyer/Rejection-Catalogue")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/buyer/Rejection-Catalogue"
                    >
                      Rejected Catalogue
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
          )}

          {(loginUserRole === "buyerSuperAdmin" ||
            loginUserRole === "bppId") && (
            <li
              className={
                this.isPathActive("/error-pages")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <div
                className={
                  this.state.settingManagerOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("settingManagerOpen")}
                data-toggle="collapse"
              >
                <img alt="" className="sidebar_icon" src={settingmanager} />
                <span className="menu-title">Setting Manager</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.settingManagerOpen}>
                <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/buyer/carousel-manager")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/buyer/carousel-manager"
                    >
                     Carousel Manager
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/app-settings")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/app-settings"
                    >
                     App Settings
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
          )}

          {/* {loginUserRole === 'buyerSuperAdmin' &&
            <li
              className={
                this.isPathActive('/fb-carousel-manager') ? 'nav-item active' : 'nav-item'
              }
            >
              <Link className='nav-link' to='/fb-carousel-manager'>
                <img alt="" className='sidebar_icon' src={dashboard} />
                <span className='menu-title'>FB Carousel Manager</span>
              </Link>
            </li>
          } */}

          {(loginUserRole === "buyerSuperAdmin" ||
            loginUserRole === "bppId") && (
            <li
              className={
                this.isPathActive("/buyer-log-manager")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/buyer-log-manager">
                <img alt="" className="sidebar_icon" src={dashboard} />
                <span className="menu-title">Logs Manager</span>
              </Link>
            </li>
          )}
          {loginUserRole === "buyerSuperAdmin" && (
            <li
              className={
                this.isPathActive("/buyer-product-tag")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/buyer-product-tag">
                <img alt="" className="sidebar_icon" src={dashboard} />
                <span className="menu-title">Product Tag's</span>
              </Link>
            </li>
          )}
          {/* {loginUserRole === 'buyerSuperAdmin' &&
            <li
              className={
                this.isPathActive('/buyer/ondc-settlements') ? 'nav-item active' : 'nav-item'
              }
            >
              <Link className='nav-link' to='/buyer/ondc-settlements'>
                <img alt="" className='sidebar_icon' src={dashboard} />
                <span className='menu-title'>Ondc Settlements
                </span>
              </Link>
            </li>
          } */}
          {(loginUserRole === "buyerSuperAdmin" ||
            loginUserRole === "bppId") && (
            <li
              className={
                this.isPathActive("/buyer-ondc-orders")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/buyer-ondc-orders">
                <img alt="" className="sidebar_icon" src={dashboard} />
                <span className="menu-title">Ondc Orders</span>
              </Link>
            </li>
          )}

          {(loginUserRole === "buyerSuperAdmin" ||
            loginUserRole === "bppId") && (
            <li
              className={
                this.isPathActive("/error-pages")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <div
                className={
                  this.state.BuyerOrderManagerOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("BuyerOrderManagerOpen")}
                data-toggle="collapse"
              >
                <img alt="" className="sidebar_icon" src={sellerrewards} />
                <span className="menu-title">ONDC Manager</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse
                in={
                  this.state.BuyerOrderManagerOpen ||
                  this.state.buyerRsfDashBoardOpen
                }
              >
                <ul className="nav flex-column sub-menu">
                  {(loginUserRole === "buyerSuperAdmin" ||
                    loginUserRole === "bppId") && (
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/buyer-ondc-order-manager")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/buyer-ondc-order-manager"
                      >
                        ONDC Order Manager
                      </Link>
                    </li>
                  )}
                  {loginUserRole === "buyerSuperAdmin" && (
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/buyer-ondc-pending-order")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/buyer-ondc-pending-order"
                      >
                        ONDC Pending Order
                      </Link>
                    </li>
                  )}
                  {loginUserRole === "buyerSuperAdmin" && (
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/buyer-error")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/buyer-error"
                      >
                        ONDC Order Error
                      </Link>
                    </li>
                  )}
                  {loginUserRole === "buyerSuperAdmin" && (
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/buyer-igm")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/buyer-igm"
                      >
                        IGM Dashboard
                      </Link>
                    </li>
                  )}
                  {loginUserRole === "buyerSuperAdmin" && (
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/coupon-manager")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/coupon-manager"
                      >
                        Coupon Manager
                      </Link>
                    </li>
                  )}

                  {(loginUserRole === "buyerSuperAdmin" ||
                    loginUserRole === "bppId") && (
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/buyer/ondc-settlements")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/buyer/ondc-settlements"
                      >
                        Ondc Settlements
                      </Link>

                      <li>
                        <div
                          className={
                            this.state.buyerRsfDashBoardOpen
                              ? "nav-link menu-expanded"
                              : "nav-link"
                          }
                          onClick={() =>
                            this.toggleMenuState("buyerRsfDashBoardOpen")
                          }
                          data-toggle="collapse"
                        >
                          <span className="menu-title">RSF Dashboard</span>
                          <i className="menu-arrow"></i>
                        </div>
                        <Collapse in={this.state.buyerRsfDashBoardOpen}>
                          <ul className="nav flex-column sub-menu">
                            {loginUserRole === "buyerSuperAdmin" && (
                              <li className="nav-item">
                                <Link
                                  className={
                                    this.isPathActive(
                                      "/buyer-rsf-settle-requests"
                                    )
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  to="/buyer-rsf-settle-requests"
                                >
                                  RSF Settle Requests
                                </Link>
                              </li>
                            )}
                            {loginUserRole === "buyerSuperAdmin" && (
                              <li className="nav-item">
                                <Link
                                  className={
                                    this.isPathActive(
                                      "/buyer-rsf-settled-requests"
                                    )
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  to="/buyer-rsf-settled-requests"
                                >
                                  RSF Settled Orders
                                </Link>
                              </li>
                            )}

                            {loginUserRole === "buyerSuperAdmin" && (
                              <li className="nav-item">
                                <Link
                                  className={
                                    this.isPathActive("/buyer-rsf-logs-manager")
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  to="/buyer-rsf-logs-manager"
                                >
                                  RSF Log Manager
                                </Link>
                              </li>
                            )}
                          </ul>
                        </Collapse>
                      </li>
                    </li>
                  )}
                  {/* {(loginUserRole === 'buyerSuperAdmin') && <li className='nav-item'

                  >
                    <Link className={
                      this.isPathActive('/buyer/payment-data')
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                      to='/buyer/payment-data'
                    >
                      Payment Data

                    </Link>
                  </li>} */}
                </ul>
              </Collapse>
            </li>
          )}
          {loginUserRole === "buyerSuperAdmin" && (
            <li
              className={
                this.isPathActive("/buyer/payment-data")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/buyer/payment-data">
                <img alt="" className="sidebar_icon" src={dashboard} />
                <span className="menu-title">Payment Data</span>
              </Link>
            </li>
          )}
          {loginUserRole === "buyerSuperAdmin" && (
            <li
              className={
                this.isPathActive("/buyer-report-manager")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/buyer-report-manager">
                <img alt="" className="sidebar_icon" src={dashboard} />
                <span className="menu-title">Report Manager</span>
              </Link>
            </li>
          )}

          {(loginUserRole === "buyerSuperAdmin" ||
            loginUserRole === "bppId") && (
            <li
              className={
                this.isPathActive("/stores") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.storeManagerMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("storeManagerMenuOpen")}
                data-toggle="collapse"
              >
                <img alt="" className="sidebar_icon" src={usermanager} />
                <span className="menu-title">Store Manager</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse
                in={
                  this.state.storeManagerMenuOpen ||
                  this.state.storeMenuOpen ||
                  this.state.buyerMenuOpen
                }
              >
                <ul className="nav flex-column sub-menu">
                  {loginUserRole !== "marketinguser" && (
                    <li
                      className={
                        this.isPathActive("/buyer/StoreManager")
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <div
                        className={
                          this.state.storeMenuOpen
                            ? "nav-link menu-expanded"
                            : "nav-link"
                        }
                        onClick={() => this.toggleMenuState("storeMenuOpen")}
                        data-toggle="collapse"
                      >
                        {/* <span className='menu-title'>Buyer</span> */}
                        <Link
                          className={
                            this.isPathActive("/buyer/StoreManager")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/buyer/StoreManager"
                        >
                          All Stores
                          <i className="menu-arrow"></i>
                        </Link>
                      </div>

                      {/* <Collapse in={this.state.storeMenuOpen}>
                        <ul className='nav flex-column sub-menu'>
                          {loginUserRole === 'buyerSuperAdmin' &&
                            <li className='nav-item sub_na_item'>
                              <Link
                                className={
                                  this.isPathActive('/stores-manager')
                                    ? 'nav-link active'
                                    : 'nav-link'
                                }
                                to='/buyer-user-manager'
                              >
                                All Buyers
                              </Link>
                            </li>
                          }
                        </ul>
                      </Collapse> */}
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}
          {loginUserRole === "buyerSuperAdmin" && (
            <li
              className={
                this.isPathActive("/search-gateway")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/search-gateway">
                <img alt="" className="sidebar_icon" src={dashboard} />
                <span className="menu-title">Search Gateway</span>
              </Link>
            </li>
          )}
          {loginUserRole === "buyerSuperAdmin" && (
            <li
              className={
                this.isPathActive("/error-pages")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <div
                className={
                  this.state.SellerNpManagerOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("SNPManagerOpen")}
                data-toggle="collapse"
              >
                <img alt="" className="sidebar_icon" src={walletmanager} />
                <span className="menu-title">SNP Manager</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.SNPManagerOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/snp-manager")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/snp-manager"
                    >
                      SNP Manager
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/sellerOnboard-form")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/sellerOnboard-form"
                    >
                      SNP Onboarding Form
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
          )}

          {loginUserRole === "buyerSuperAdmin" && (
            <li
              className={
                this.isPathActive("/users") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.userManagerMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("userManagerMenuOpen")}
                data-toggle="collapse"
              >
                <img alt="" className="sidebar_icon" src={usermanager} />
                <span className="menu-title">User Manager</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse
                in={
                  this.state.userManagerMenuOpen ||
                  this.state.sellersMenuOpen ||
                  this.state.buyerMenuOpen
                }
              >
                <ul className="nav flex-column sub-menu">
                  {loginUserRole !== "marketinguser" && (
                    <li
                      className={
                        this.isPathActive("/buyer-user-manager")
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <div
                        className={
                          this.state.sellersMenuOpen
                            ? "nav-link menu-expanded"
                            : "nav-link"
                        }
                        onClick={() => this.toggleMenuState("sellersMenuOpen")}
                        data-toggle="collapse"
                      >
                        <span className="menu-title">Buyer</span>
                        <i className="menu-arrow"></i>
                      </div>
                      <Collapse in={this.state.sellersMenuOpen}>
                        <ul className="nav flex-column sub-menu">
                          {loginUserRole === "buyerSuperAdmin" && (
                            <li className="nav-item sub_na_item">
                              <Link
                                className={
                                  this.isPathActive("/buyer-user-manager")
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                to="/buyer-user-manager"
                              >
                                All Buyers
                              </Link>
                            </li>
                          )}
                        </ul>
                      </Collapse>
                      <Collapse in={this.state.sellersMenuOpen}>
                        <ul className="nav flex-column sub-menu">
                          {loginUserRole === "buyerSuperAdmin" && (
                            <li className="nav-item sub_na_item">
                              <Link
                                className={
                                  this.isPathActive("/tabe-buyers")
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                to="/tabe-buyers"
                              >
                                Tabe Buyers
                              </Link>
                            </li>
                          )}
                        </ul>
                      </Collapse>
                      <Collapse in={this.state.sellersMenuOpen}>
                        <ul className="nav flex-column sub-menu">
                          {loginUserRole === "buyerSuperAdmin" && (
                            <li className="nav-item sub_na_item">
                              <Link
                                className={
                                  this.isPathActive("/tabe-ambassadors")
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                to="/tabe-ambassadors"
                              >
                                Tabe Ambassadors
                              </Link>
                            </li>
                          )}
                        </ul>
                      </Collapse>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  async componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
